.italics {
  font-size: .8em;
  color: grey;
}

.environment_cost {
  font-size: .7em;
  color: rgb(12, 11, 11);
}

body {

  min-height: 100vh;
  max-height: 400%;
  background-image: url("home5.svg") !important;
  background-color: #121D39;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

}

tr:hover {
  color: white !important;
}

